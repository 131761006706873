import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../logo-ecommerce.png';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {
    obtenerPreguntas as apiObtenerPreguntas,
    guardarRespuesta as apiGuardarRespuesta,
} from "../api/api";
import { useHistory } from 'react-router-dom';
import Loading from "../components/Loading";
import { formatoFechaYMD } from "../utils/fechas";
import Alert from 'react-bootstrap/Alert'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer';
import Finalizado from "./Finalizado";

function EncuestaEcommerce(props) {
    const [preguntas, setPreguntas] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [finalizado, setFinalizado] = useState(false);
    const history = useHistory();
    const [showMessageError, setShowMessageError] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [validated, setValidated] = useState(false);
    const [showMessageSuccess, setShowMessageSuccess] = useState(false);

    const [messageSuccess, setMessageSuccess] = useState(null);
    const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);

    const seleccionarPuntaje = (item, nuevoValor) => {
        console.log(nuevoValor);
        let pregunta = preguntas.find(row => item.idPlantillaEncuestaPregunta == row.idPlantillaEncuestaPregunta);
        let opcionRespuesta = item.preguntaOpcionRespuesta?.find(p => p.valor.trim() === nuevoValor.toString());

        pregunta.respuestaDetalle = opcionRespuesta?.descripcionOpcion || nuevoValor;
        pregunta.idOpcionRespuesta = opcionRespuesta?.idOpcionRespuesta || null;

        let preguntaAsociada = item.preguntasHijas ? item.preguntasHijas.filter(row => row.condicion.split(',').map(cond => cond.trim()).includes(nuevoValor.toString())) : [];
        let nuevaListaPreguntasAdicionales = eliminarPreguntasEnCascada(preguntas, item.idPlantillaEncuestaPregunta);

        if (preguntaAsociada.length > 0) {
            nuevaListaPreguntasAdicionales = [...nuevaListaPreguntasAdicionales, ...preguntaAsociada];
        }

        nuevaListaPreguntasAdicionales = ordenarPreguntas(nuevaListaPreguntasAdicionales)
        setPreguntas(nuevaListaPreguntasAdicionales);
        console.log(preguntas);
    };

    const seleccionarOpcion = (e, pregunta, opcion) => {
        console.log(preguntas);
        let nuevaLista = preguntas ? preguntas : [];

        setOpcionesSeleccionadas({
            ...opcionesSeleccionadas,
            [pregunta.idPlantillaEncuestaPregunta]: {
                idOpcionRespuesta: opcion.idOpcionRespuesta,
                flgTexto: opcion.flgTexto
            }
        });

        //Insertar detalle a la pregunta asociada
        let indicePregunta = nuevaLista.findIndex(p => p.idPlantillaEncuestaPregunta === pregunta.idPlantillaEncuestaPregunta);

        if (indicePregunta !== -1) {
            nuevaLista[indicePregunta].respuestaDetalle = opcion.flgTexto ? null : opcion.descripcionOpcion;
            nuevaLista[indicePregunta].idOpcionRespuesta = opcion.idOpcionRespuesta  ? opcion.idOpcionRespuesta : null;      
        } else {
            nuevaLista.push({...pregunta
                , respuestaDetalle: opcion.flgTexto ? null : opcion.descripcionOpcion
                , idOpcionRespuesta: opcion.idOpcionRespuesta  ? opcion.idOpcionRespuesta : null
            });
        }
        
        //Eliminacion de preguntas hijas
        let preguntaAsociada = pregunta.preguntasHijas ? pregunta.preguntasHijas.filter(row => row.condicion.split(',').map(cond => cond.trim()).includes(opcion.valor.trim().toString())) : [];
        nuevaLista = eliminarPreguntasEnCascada(nuevaLista, pregunta.idPlantillaEncuestaPregunta);

        if (preguntaAsociada.length > 0) {
            nuevaLista = [...nuevaLista, ...preguntaAsociada];
        }

        nuevaLista = ordenarPreguntas(nuevaLista)        
        setPreguntas(nuevaLista);
        console.log(preguntas);
    };

    const eliminarPreguntasEnCascada = (nuevaLista, preguntaId) => {
        // Recursivamente encuentra todos los idPreguntaPadre que deben ser eliminados
        const encontrarPreguntasAsociadas = (lista, id) => {
            return lista.reduce((acc, item) => {
                if (item.idPreguntaPadre === id) {
                    return acc.concat(item.idPlantillaEncuestaPregunta, encontrarPreguntasAsociadas(lista, item.idPlantillaEncuestaPregunta));
                }
                return acc;
            }, []);
        };
    
        const idsAEliminar = encontrarPreguntasAsociadas(nuevaLista, preguntaId);
    
        // Elimina los elementos cuyo idPreguntaPadre está en la lista de idsAEliminar
        return nuevaLista.filter(item => !idsAEliminar.includes(item.idPlantillaEncuestaPregunta));
    };

    const ordenarPreguntas = (lista) => {
        return lista.sort((a, b) => {
            if (a.nivelPregunta !== b.nivelPregunta) return a.nivelPregunta - b.nivelPregunta;
            if (a.idPreguntaPadre !== b.idPreguntaPadre) return a.idPreguntaPadre - b.idPreguntaPadre;
            return a.nroOrden - b.nroOrden;
        });
    };

    const escribirObservacion = (item, event) => {
        console.log(item);
        let pregunta = preguntas.find(row => item.idPlantillaEncuestaPregunta == row.idPlantillaEncuestaPregunta);
        pregunta.respuestaDetalle = event.target.value;
        setPreguntas(preguntas);
        console.log(preguntas);
    };

    const validarRespuestas = async () => {
        let sinResonder = 0;
        preguntas.map(item => {
            if (item.respuestaDetalle == null && item.flgObligatorio == true) {
                sinResonder++;
            }
        });
        return sinResonder;
    };

    const obtenerPreguntas = () => {
        let payload = { CodEncuesta: props.match.params.CodEncuesta }
        apiObtenerPreguntas(payload).then(response => {
            setCargando(false);
            setPreguntas(response);
        }).catch(error => {
            console.log("error", error.errors);
            setCargando(false);
        });

    };

    const mostrarAlertaError = (mensaje) => {
        setMessageError(mensaje);
        setShowMessageError(true);
    };

    const mostrarAlertaExito = (mensaje) => {
        setMessageSuccess(mensaje);
        setShowMessageSuccess(true);
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        const validado = await validarRespuestas();
        if (form.checkValidity() === false || validado > 0) {
            event.preventDefault();
            event.stopPropagation();
            mostrarAlertaError("Llenar los campos obligatorios");
        } else {
            guardarRespuesta();
        }
        setValidated(true);
    };
    
    const guardarRespuesta = async () => {
        setCargando(true);
        console.log("Data enviada", preguntas);
        if (preguntas.length > 0) {
            let payload = {
                LstInterfaceEncuestaRespuestaRequest: preguntas
            }
            apiGuardarRespuesta(payload).then(response => {
                setCargando(false);
                mostrarAlertaExito("Sus respuestas se guardaron correctamente");
                setFinalizado(true);
                //history.push("/finalizado");
            }).catch(error => {
                mostrarAlertaError("No se pudieron guardar sus respuestas correctamente");
                setCargando(false);
            });
        }
    };

    useEffect(() => {
        obtenerPreguntas()
    }, [])

    if (cargando == true) {
        return <Loading />
    } else if (finalizado == true) {
        return <Finalizado logo={logo} />
    } else {
        return (
            <Container>
                <ToastContainer position="top-end" className="p-3">
                    <Toast bg='danger' onClose={() => setShowMessageError(false)} show={showMessageError} delay={3000} >
                        <Toast.Header>
                            <strong className="me-auto">Advertencia</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">{messageError}</Toast.Body>
                    </Toast>
                    <Toast bg='success' onClose={() => setShowMessageSuccess(false)} show={showMessageSuccess} delay={3000} >
                        <Toast.Header>
                            <strong className="me-auto">Éxito</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">{messageSuccess}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <Row className="p-3 justify-content-md-center">
                    <Col lg="6">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col className="text-center mt-3 mb-5">
                                        <img className="logo" src={logo} />
                                    </Col>

                                </Row>
                                {preguntas?.length > 0 ?
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        {preguntas?.map(item => {

                                            if (item.tipPregunta == "1") {
                                                return (
                                                    <Row key={item.idPlantillaEncuestaPregunta} className="mt-3">
                                                        <Col lg="8">{item.descPregunta}</Col>
                                                        <Col lg="3">
                                                            <ReactStars
                                                                count={item.opcionRespuesta?.length > 0 ? item.opcionRespuesta.length : 5}
                                                                required={item.flgObligatorio}
                                                                onChange={(nuevoValor) => { seleccionarPuntaje(item, nuevoValor) }}
                                                                size={24}
                                                                activeColor="#ffd700"
                                                            />
                                                        </Col>
                                                        {item.flgObligatorio ? <Col lg="1"><p className="text-danger">(*)</p></Col> : null}

                                                    </Row>
                                                )
                                            } else if (item.tipPregunta == "3") {
                                                return (
                                                    <div key={item.idPlantillaEncuestaPregunta}>
                                                        <Row className="mt-3">
                                                            <Col>{item.descPregunta}</Col>
                                                            {item.flgObligatorio ? <Col lg="1"><p className="text-danger">(*)</p></Col> : null}
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col className="row">
                                                                {item.preguntaOpcionRespuesta?.map(opcion => (
                                                                    <React.Fragment key={opcion.idOpcionRespuesta}>
                                                                        <Form.Check className="col-lg-6"
                                                                            type="radio"
                                                                            name={`pregunta-${item.idPlantillaEncuestaPregunta}`}
                                                                            id={opcion.valor}
                                                                            label={opcion.descripcionOpcion}
                                                                            onChange={(e) => seleccionarOpcion(e, item, opcion)}
                                                                        />
                                                                        {opcionesSeleccionadas[item.idPlantillaEncuestaPregunta]?.idOpcionRespuesta === opcion.idOpcionRespuesta &&
                                                                            opcion.flgTexto && (
                                                                                <Col lg={12} style={{ paddingTop: '7px' }}>
                                                                                    <Form.Control
                                                                                        required={item.flgObligatorio}
                                                                                        className="inputMaterial"
                                                                                        onChange={(event) => escribirObservacion(item, event)}
                                                                                        type="text"
                                                                                        placeholder="Escribe tu comentario aquí"
                                                                                        maxLength={50}
                                                                                    />
                                                                                </Col>
                                                                            )}
                                                                    </React.Fragment>
                                                                ))}  
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            }
                                            else {
                                                return (
                                                    <div key={item.idPlantillaEncuestaPregunta}>
                                                        <Row className="mt-3">
                                                            <Col>{item.descPregunta}</Col>
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col>
                                                                <Form.Control required={item.flgObligatorio} className="inputMaterial" onChange={(event) => { escribirObservacion(item, event) }} type="text" />
                                                            </Col>
                                                            {item.flgObligatorio ? <Col lg="1"><p className="text-danger">(*)</p></Col> : null}
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                        })}
                                        <Row className="mt-3">
                                            <Col className="text-center">
                                                <Button type="submit" disabled={cargando} variant="warning">
                                                    <FontAwesomeIcon icon={faPaperPlane} />ENVIAR
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    : <Alert variant="warning">
                                        <Alert.Heading>Lo sentimos</Alert.Heading>
                                        <p>
                                            No hay información para está encuesta o ya ha sido respondida
                                        </p>
                                    </Alert>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

}
export default EncuestaEcommerce;