import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../logo.png';
import error403 from '../error403.png';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { obtenerPreguntas as apiObtenerPreguntas } from "../api/api";

function Prohibido() {


    return (
        <Container>
            <Row className="p-3 justify-content-md-center">
                <Col lg="6">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="text-center mt-3 mb-5">
                                    <img className="logo" src={logo} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-3 mb-5">
                                    <img className="img-fluid" src={error403} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}
export default Prohibido;