import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Loading() {

    return (
        <Container>
            <Row className="p-3 justify-content-md-center">
               Cargando ....
            </Row>
        </Container>
    )
}
export default Loading;