import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Encuesta from './views/Encuesta';
import EncuestaEcommerce from './views/EncuestaEcommerce';
import Prohibido from './views/Prohibido';


function App() {

  return (

    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path='/' component={Prohibido} />   
        <Route exact path='/survey-dinet/:CodEncuesta' component={Encuesta} />
        <Route exact path='/survey-ecommerce/:CodEncuesta' component={EncuestaEcommerce} />
      </Switch>
    </Router>
  );
}

export default App;
