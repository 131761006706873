import axios from 'axios';
import env from "react-dotenv";

const URL_BASE = process.env.REACT_APP_BASE_URL;

const headers = {
    //'x-functions-key': 'SziaUriY1naPI6UwVDVHzX/7Oq52mdkqB/HyeGNavrCL3yhW2TUZaQ==' //TEST
    'x-functions-key': 'QxV63ozJs62/TrrMxSIfrPvqFhJJ1EUgw8MQaifmphmSM2qPazAFiw==' //PROD
}

export async function guardarRespuesta(data) {
    axios.post(URL_BASE + "encuesta/guardarRespuesta", data,{
        headers: headers
      }).then(r => {
        console.log("Preguntas", r.data);
    })
}

export async function obtenerPreguntas(data) {
    
    let lstPreguntas = [];
    await axios.post(URL_BASE + "encuesta/preguntas", data,{
        headers: headers
      }).then(r => {
        lstPreguntas = r.data.data.preguntas
    })
    return lstPreguntas;
}
